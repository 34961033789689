<template>
  <div class="user-page-wrapper">
    <AddSocials
      v-if="isAuthUserAsAdmin || isAuthUserRegional"
      :visible="addSocialsDialog"
      @close="addSocialsDialog = false"
      @save="addSocial($event)"
    ></AddSocials>
    <ConfirmPopup
      :show="confirm.show"
      :text="confirm.text"
      @close="closeConfirm"
      @confirm="confirmed"
    ></ConfirmPopup>
    <div class="g-container">
      <div class="user-create">
        <h1 class="title" v-if="mode === 'create'">Добавить партнера</h1>
        <h1 class="title" v-if="mode === 'edit'">{{ partnerName }}</h1>
        <div class="btn-wrapper">
          <button class="btn btn-top btn-green" @click="savePartner">
            Сохранить
          </button>
          <button
            v-if="isAuthUserAsAdmin || isAuthUserRegional"
            class="btn btn-top btn-gray"
            @click="confirmDelete"
          >
            Удалить
          </button>
          <button class="btn btn-top btn-gray" @click="confirmExit">
            Закрыть
          </button>
        </div>
      </div>
      <div class="create">
        <div class="tabs">
          <div
            class="tab"
            :class="{ ' active': tab === 0, active: tab === 0 }"
            @click="tab = 0"
          >
            Общая информация
          </div>
          <div
            v-for="direction in selectedDirections"
            :key="direction.id"
            class="tab"
            :class="{
              ' active': tab === direction.id,
              active: tab === direction.id,
            }"
            @click="tab = direction.id"
          >
            {{ direction.name }}
          </div>
          <div
            class="tab"
            :class="{ ' active': tab === 1, active: tab === 1 }"
            @click="tab = 1"
          >
            Параметры
          </div>
        </div>
        <template v-if="tab === 0">
          <div class="form">
            <div class="form-item">
              <p class="text">
                Внимание! Поля обозначенные знаком * обязательны к заполнению!
              </p>
            </div>
            <div class="tab-content">
              <div class="content-item">
                <div class="form-item">
                  <label class="form-label">Бизнес-регион*</label>
                  <div class="form-select-wrapper">
                    <template>
                      <el-select
                        @change="formTouched = true"
                        v-model="selectedRegions"
                        multiple
                        collapse-tags
                        filterable
                        placeholder="Выберите или введите название"
                        class="form-select"
                        :class="{ error: selectedRegions.length === 0 }"
                      >
                        <el-option
                          v-for="region in businessRegions"
                          :key="region.id"
                          class="select-checkbox"
                          :label="region.name"
                          :value="region.id"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                  <p
                    v-show="selectedRegions.length === 0"
                    class="message-error"
                  >
                    Выберите хотя бы один бизнес-регион
                  </p>
                </div>
                <div class="form-item" v-if="selectedRegions.length">
                  <div class="region-title">
                    <p class="form-label">Название бизнес-региона</p>
                  </div>
                  <ul class="region-list">
                    <li
                      class="region-list__item"
                      v-for="selectedRegion in selectedBusinessRegions"
                      v-bind:key="selectedRegion.id"
                    >
                      <p>{{ selectedRegion.name }}</p>
                      <div
                        class="cursor-pointer"
                        @click="removeSelectedRegion(selectedRegion.id)"
                      >
                        <img
                          class="region-list__icon"
                          src="@/assets/images/svg/close.svg"
                          alt="удалить регион"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="form-item">
                  <label class="form-label">Тип партнера*</label>
                  <div class="form-select-wrapper">
                    <template>
                      <el-select
                        @change="formTouched = true"
                        :disabled="!isAuthUserAsAdmin && !isAuthUserRegional"
                        filterable
                        v-model="partnerType"
                        placeholder="Выберите или введите название"
                        class="form-select"
                        :class="{ error: !partnerType }"
                      >
                        <el-option
                          v-for="type in types"
                          :key="type.id"
                          :label="type.name"
                          :value="type.id"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                  <p v-show="!partnerType" class="message-error">
                    Выберите тип партнера
                  </p>
                </div>
                <div class="form-item">
                  <label class="form-label">Признак партнера</label>
                  <div class="form-select-wrapper">
                    <template>
                      <el-select
                        @change="formTouched = true"
                        v-model="selectedPartnerAttributeIds"
                        :disabled="!isAuthUserAsAdmin && !isAuthUserRegional"
                        multiple
                        collapse-tags
                        filterable
                        placeholder="Выберите или введите название"
                        class="form-select"
                      >
                        <el-option
                          v-for="attr in attributes"
                          :key="attr.id"
                          class="select-checkbox"
                          :label="attr.name"
                          :value="attr.id"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                </div>
                <div
                  class="form-item"
                  v-if="selectedPartnerAttributeIds.length"
                >
                  <ul class="region-list">
                    <li
                      class="region-list__item"
                      v-for="item in selectedPartnerAttributes"
                      v-bind:key="item.id"
                    >
                      <p>{{ item.name }}</p>
                      <div
                        class="cursor-pointer"
                        @click="removeSelectedPartnerAttributeId(item.id)"
                      >
                        <img
                          class="region-list__icon"
                          src="@/assets/images/svg/close.svg"
                          alt="удалить признак"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="form-item-wrapper">
                  <div class="form-item">
                    <label class="form-label" for="name">Название*</label>
                    <input
                      @change="formTouched = true"
                      :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                      id="name"
                      class="form-input"
                      :class="{ error: !partnerName }"
                      type="text"
                      v-model="partnerName"
                    />
                    <p v-show="!partnerName" class="message-error">
                      Это поле обязательно к заполнению
                    </p>
                  </div>
                  <div class="form-item">
                    <label class="form-label" for="inn">ИНН</label>
                    <input
                      @change="formTouched = true"
                      :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                      id="inn"
                      class="form-input"
                      type="text"
                      v-model="inn"
                    />
                  </div>
                </div>
                <div class="form-item-wrapper">
                  <div class="form-item">
                    <label class="form-label" for="phone1">Телефон 1*</label>
                    <input
                      @change="formTouched = true"
                      :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                      id="phone1"
                      class="form-input"
                      type="tel"
                      v-model="phone_1"
                      :class="{ error: !phone_1 }"
                    />
                    <p v-show="!phone_1" class="message-error">
                      Это поле обязательно к заполнению
                    </p>
                    <p class="text-small">
                      Внести номер телефона через +7 (для России)
                    </p>
                  </div>
                  <div class="form-item">
                    <label class="form-label" for="phone2">Телефон 2</label>
                    <input
                      @change="formTouched = true"
                      :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                      id="phone2"
                      class="form-input"
                      type="tel"
                      v-model="phone_2"
                    />
                    <p class="text-small">
                      Внести номер телефона через +7 (для России)
                    </p>
                  </div>
                </div>
                <div class="form-item-wrapper">
                  <div class="form-item">
                    <label class="form-label" for="email1">Email 1</label>
                    <input
                      @change="validateEmail(1)"
                      :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                      id="email1"
                      class="form-input"
                      :class="{ error: !email_1_valid }"
                      type="email"
                      v-model="email_1"
                    />
                    <p v-show="!email_1_valid" class="message-error">
                      Неверный формат email
                    </p>
                  </div>
                  <div class="form-item">
                    <label class="form-label" for="email2">Email 2</label>
                    <input
                      @change="validateEmail(2)"
                      :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                      id="email2"
                      class="form-input"
                      :class="{ error: !email_2_valid }"
                      type="email"
                      v-model="email_2"
                    />
                    <p v-show="!email_2_valid" class="message-error">
                      Неверный формат email
                    </p>
                  </div>
                </div>
                <div class="form-item">
                  <label class="form-label" for="link">Ссылка URL</label>
                  <input
                    @change="formTouched = true"
                    :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                    id="link"
                    class="form-input"
                    type="url"
                    v-model="website"
                  />
                  <p class="text-small">Внести адрес с протоколом https://</p>
                </div>
                <div class="form-item">
                  <p class="form-label">Соцсети</p>
                  <table class="socials-table" v-if="selectedSocials.length">
                    <thead>
                      <tr class="socials-table__head">
                        <th>Название</th>
                        <th>Ссылка</th>
                        <th>Иконка</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(page, index) in selectedSocials"
                        :key="page.id + page.link"
                      >
                        <td>{{ page.name }}</td>
                        <td class="pink">{{ page.link }}</td>
                        <td>
                          <img
                            :src="
                              page.image
                                ? page.image.thumbnail_link
                                : page.image_link
                            "
                            :alt="page.name"
                          />
                        </td>
                        <td>
                          <img
                            class="region-list__icon"
                            src="@/assets/images/svg/close.svg"
                            alt=""
                            @click="deleteSocial(index)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="socials-list" v-else>
                    <p>Добавьте соцсети</p>
                  </div>
                  <div
                    v-if="isAuthUserAsAdmin || isAuthUserRegional"
                    class="btn-wrapp"
                  >
                    <button
                      class="btn btn-gray"
                      @click="addSocialsDialog = true"
                    >
                      Добавить соцсеть
                    </button>
                  </div>
                </div>
                <!--Сертификация-->
                <Certification
                  :hasCert="hasCert"
                  :businessRegions="selectedBusinessRegions"
                  :address="address"
                  :inn="inn"
                  :isAuth="!isAuthUserAsAdmin && !isAuthUserRegional"
                  :isCoordsSet="coords.set"
                  :partnerCertificates="certificateLinks.length ? certificateLinks : updatedCertificates"
                  :partnerCertificationAuthority="certificateCA"
                  @update="updatedCertificates = $event"
                  @isCert="hasCert = $event"
                  @change="formTouched = $event"
                  @certificateCA="certificateCA = $event"
                />
              </div>
              <div class="content-item">
                <div class="form-item" id="mini-map">
                  <MiniMap
                    :coords="[coords.lat, coords.long]"
                    :directions="selectedDirections"
                    :point="coords.set"
                    :marker="markerType"
                    @setPoint="setCoords($event)"
                  ></MiniMap>
                </div>
                <div class="form-item">
                  <p class="text-small">
                    Вид иконки зависит от выбора бизнес-региона и типа партнера
                  </p>
                </div>
                <div class="form-item">
                  <label class="form-label" for="cladr">Код КЛАДР</label>
                  <input
                    @change="formTouched = true"
                    :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                    id="cladr"
                    class="form-input"
                    type="text"
                    v-model="cladr"
                  />
                </div>
                <div class="form-item">
                  <label class="form-label" for="fias">Код ФИАС</label>
                  <input
                    @change="formTouched = true"
                    :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                    id="fias"
                    class="form-input"
                    type="text"
                    v-model="fias"
                  />
                </div>
                <div class="form-item">
                  <label class="form-label" for="address">Адрес*</label>
                  <input
                    @input="addressHandler"
                    :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                    id="address"
                    class="form-input"
                    type="text"
                    v-model="address"
                  />
                  <ul
                    class="form-hints"
                    v-show="
                      showHints && (isAuthUserAsAdmin || isAuthUserRegional)
                    "
                  >
                    <li
                      v-for="hint in addressHints"
                      :key="hint.value"
                      @click="validateAddress(hint.value)"
                    >
                      {{ hint.value }}
                    </li>
                  </ul>
                </div>
                <div class="form-item">
                  <label class="form-label" for="coords">Координаты</label>
                  <input
                    id="coords"
                    class="form-input"
                    type="text"
                    :value="coords.set ? coordsToString : ''"
                    readonly
                  />
                </div>
                <div class="form-item">
                  <input
                    @change="formTouched = true"
                    :disabled="isDisabledMapVisible"
                    id="active"
                    class="form-input-check"
                    type="checkbox"
                    v-model="mapVisible"
                  />
                  <label
                    for="active"
                    class="form-label form-label-check form-label-check_black"
                    >Отображать на карте</label
                  >
                </div>
                <div class="form-item">
                  <p class="form-label">Описание</p>
                  <textarea
                    @change="formTouched = true"
                    :readonly="!isAuthUserAsAdmin && !isAuthUserRegional"
                    class="form-input form-textarea"
                    v-model="description"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-for="direction in selectedDirections">
          <div
            class="tab-content"
            v-if="tab === direction.id"
            :key="direction.id"
          >
            <form class="form">
              <div class="facility">
                <div
                  class="facility-item"
                  :style="`display: ${
                    group.direction !== tab ? 'none' : 'block'
                  };`"
                  v-for="(group, index) in productGroups"
                  :key="index"
                >
                  <div v-if="group.direction === tab">
                    <h4 class="facility-list-title">{{ group.name }}</h4>
                    <ul class="facility-list">
                      <li
                        class="facility-list__item"
                        v-for="product in group.products"
                        :key="product.id"
                      >
                        <input
                          @change="formTouched = true"
                          :disabled="!isAuthUserAsAdmin && !isAuthUserRegional"
                          v-model="product.selected"
                          :id="product.id"
                          type="checkbox"
                          class="form-input-check"
                        />
                        <label
                          :for="product.id"
                          class="form-label form-label-check"
                          >{{ product.name }}</label
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </template>
        <template v-if="tab === 1">
          <div class="tab-content">
            <form class="form">
              <div class="content-item">
                <div class="form-item form-item--parameters">
                  <label class="form-label">Куратор</label>
                  <div class="form-select-wrapper">
                    <template>
                      <el-select
                        @change="formTouched = true"
                        v-model="curators.main"
                        filterable
                        collapse-tags
                        placeholder="Выберите или введите имя"
                        class="form-select"
                        :class="{ error: !curators.main }"
                      >
                        <el-option
                          v-for="name in users"
                          :key="name.id"
                          :label="name.full_name"
                          :value="name.id"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                </div>
                <div class="form-item form-item--parameters">
                  <label class="form-label">Дополнительный куратор</label>
                  <div class="form-select-wrapper">
                    <template>
                      <el-select
                        @change="formTouched = true"
                        v-model="curators.additional"
                        multiple
                        filterable
                        collapse-tags
                        placeholder="Выберите или введите имя"
                        class="form-select"
                      >
                        <el-option
                          v-for="user in users"
                          :key="user.id"
                          :label="user.full_name"
                          :value="user.id"
                        >
                          <span class="form-select__checkbox">{{
                            user.full_name
                          }}</span>
                        </el-option>
                      </el-select>
                    </template>
                  </div>
                </div>
                <div class="form-item" v-if="curators.additional.length">
                  <div class="region-title">
                    <p class="form-label">Имя доп. куратора</p>
                    <img
                      class="region-list__icon"
                      src="@/assets/images/svg/down.svg"
                      alt=""
                    />
                  </div>
                  <ul class="region-list">
                    <li
                      class="region-list__item"
                      v-for="curator in selectedAdditionalCurators"
                      :key="curator.id"
                    >
                      <span>{{ curator.full_name }}</span>
                      <div
                        class="cursor-pointer"
                        @click="deleteCurator(curator.id)"
                        role="button"
                        aria-label="удалить доп куратора"
                      >
                        <img
                          class="region-list__close-icon"
                          src="@/assets/images/svg/close.svg"
                          aria-hidden="true"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="form-item" v-if="mode === 'edit'">
                  <label class="form-label" for="coords">Дата создания</label>
                  <input
                    id="created"
                    class="form-input"
                    type="text"
                    :value="parseDate(created)"
                    readonly
                  />
                </div>
                <div class="form-item" v-if="mode === 'edit'">
                  <label class="form-label" for="coords">Дата изменения</label>
                  <input
                    id="updated"
                    class="form-input"
                    type="text"
                    :value="parseDate(updated)"
                    readonly
                  />
                </div>
              </div>
            </form>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import Certification from '@/components/partner/Certification.vue';
import AddSocials from '@/components/AddSocials.vue';
import ConfirmPopup from '@/components/ConfirmPopup.vue';
import MiniMap from '@/components/MiniMap.vue';
import ValidationHelper from '@/helpers/ValidationHelper';

export default {
  name: 'PartnerCreate',
  components: {
    Certification,
    AddSocials,
    ConfirmPopup,
    MiniMap
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      cert: null,
      mode: '',
      formTouched: false,
      partnerId: '',
      partnerType: '',
      selectedPartnerAttributeIds: [],
      partnerName: '',
      selectedRegions: [],
      inn: '',
      email_1: '',
      email_2: '',
      email_1_valid: true,
      email_2_valid: true,
      phone_1: '',
      phone_2: '',
      website: '',
      description: '',
      cladr: '',
      fias: '',
      address: '',
      coords: {
        lat: 55.7558,
        long: 37.6173,
        set: false
      },
      addSocialsDialog: false,
      selectedSocials: [],
      updatedCertificates: [],
      certificateLinks: [],
      certificateCA: {},
      hasCert: true,
      mapVisible: false,
      curators: {
        main: {},
        additional: []
      },
      created: '',
      updated: '',

      tab: 0,
      is_active: false, // заменить
      confirm: {
        show: false,
        type: '',
        text: ''
      },

      adminRoleId: null,
      users: [],
      authUser: {},
      productGroups: [],
      addressHints: [],
      showHints: false
    };
  },
  async created() {
    const savedCoords = localStorage.getItem('mapcoords');
    if (savedCoords && this.$route.name !== 'partner-edit') {
      const coords = savedCoords.split(',');
      this.coords.lat = Number.parseFloat(coords[0]);
      this.coords.long = Number.parseFloat(coords[1]);
    }
    const usersData = await this.$services.UserService.getUsers({
      page_size: 10000,
      ordering: 'full_name'
    });
    this.users = usersData.results;
    await this.$store.dispatch('social/fetchSocials');
    await this.$store.dispatch('directions/fetchDirections');
    await this.$store.dispatch('business_region/fetchRegions');
    await this.$store.dispatch('partner/fetchPartnerTypes');
    await this.$store.dispatch('partner/fetchPartnerAttributes');
    await this.$store.dispatch('systems/fetchGroups');
    await this.$store.dispatch('systems/fetchProducts');
    this.productGroups = this.$store.getters['systems/getGroups'];
    this.productGroups.forEach((group) => {
      group.products = group.products.map((product) => ({
        ...product,
        selected: false
      }));
    });
    this.authUser = await this.$services.UserService.getMe();

    if (this.$route.name === 'partner-create') {
      this.mode = 'create';
      this.curators.main = this.authUser.id;
    } else if (this.$route.name === 'partner-edit' && this.$route.params.id) {
      this.isSearch = this.$route.params.isSearch
        ? this.$route.params.isSearch
        : false;
      this.mode = 'edit';
      this.partnerId = this.$route.params.id;
      const partner = await this.$services.PartnerService.getPartner(
        this.partnerId
      );
      this.selectedRegions = partner.business_regions_ids;
      this.partnerType = partner.type;
      this.selectedPartnerAttributeIds = partner.attributes_ids;
      this.partnerName = partner.name;
      this.hasCert = partner.is_sertified;
      this.certificateLinks = partner.sertificate_links;
      this.updatedCertificates = partner.certifications
      this.certificateCA = Object.keys(partner.certificateCA).filter(key => partner.certificateCA[key].certificates.length > 0).reduce((obj, key) => {
        obj[key] = partner.certificateCA[key]; // Добавление отфильтрованных элементов в новый объект
        return obj;
      }, {});
      console.info(this.certificateCA);

      this.inn = partner.inn;
      this.email_1 = partner.email_1;
      this.email_2 = partner.email_2;
      this.phone_1 = partner.phone_1;
      this.phone_2 = partner.phone_2;
      this.website = partner.web_site;
      this.productGroups.forEach((group) => {
        group.products.forEach((product) => {
          product.selected = partner.product_ids.includes(product.id);
        });
      });
      this.description = partner.description;
      this.cladr = partner.cladr;
      this.fias = partner.fias;
      this.address = partner.address;
      this.coords = {
        lat: partner.latitude,
        long: partner.longitude,
        set: !!(partner.latitude && partner.longitude)
      };
      this.mapVisible = partner.is_show_on_map;
      this.curators = {
        main: partner.curator,
        additional: partner.additional_curators_ids
      };
      this.selectedSocials = partner.social_networks.map((social) => {
        const socialNetwork = this.socials.find(
          (item) => item.id === social.id
        );
        return {
          ...social,
          name: socialNetwork.name,
          image_link:
            socialNetwork?.image_link || socialNetwork.image.thumbnail_link,
          selected: false
        };
      });
      this.created = partner.created;
      this.updated = partner.updated;
    }
  },
  beforeDestroy() {
    localStorage.setItem('mapcoords', [this.coords.lat, this.coords.long]);
  },
  watch: {
    phone_1(newVal) {
      if (newVal) {
        this.phone_1 = newVal
          .split('')
          .filter(
            (char) => Number.parseInt(char, 10) || char === '0' || char === '+'
          )
          .join('');
      }
    },
    phone_2(newVal) {
      if (newVal) {
        this.phone_2 = newVal
          .split('')
          .filter(
            (char) => Number.parseInt(char, 10) || char === '0' || char === '+'
          )
          .join('');
      }
    },
    mapVisible(newVal, oldVal) {
      // this.mapVisible = false;
      // console.log(this.mapVisible);
      // console.log(newVal);
      // console.log(oldVal);
      // if (newVal && this.certificateCA !== null) {
      //   console.log(this.certificateCA.certificates[this.certificateCA.certificates.length - 1].valid);
      //   if (newVal && !this.certificateCA.certificates[this.certificateCA.certificates.length - 1].valid) {
      //     this.mapVisible = false;
      //     this.$services.MessageService.warning(
      //       'Действие вашего сертификата истекло'
      //     );
      //   }
      // }
    }
  },
  methods: {
    confirmExit() {
      if (
        (!this.isAuthUserAsAdmin && !this.isAuthUserRegional)
        || !this.formTouched
      ) {
        this.closePartner();
        return;
      }
      this.confirm.text = 'Изменения не сохранены. Продолжить?';
      this.confirm.text = 'Изменения не сохранены. Продолжить?';
      this.confirm.type = 'close';
      this.confirm.show = true;
    },
    confirmDelete() {
      this.confirm.text = 'Восстановление удаленного партнера невозможно. Продолжить?';
      this.confirm.type = 'delete';
      this.confirm.show = true;
    },
    confirmed() {
      if (this.confirm.type === 'delete') {
        this.deletePartner();
      } else if (this.confirm.type === 'close') {
        this.closePartner();
      }
      this.closeConfirm();
    },
    closeConfirm() {
      this.confirm.show = false;
      this.confirm.text = '';
      this.confirm.type = '';
    },
    addSocial(data) {
      this.formTouched = true;
      this.addSocialsDialog = false;
      const socialNetwork = this.socials.find((item) => item.id === data.id);
      this.selectedSocials.push({
        ...socialNetwork,
        link: data.link
      });
    },
    deleteSocial(value) {
      this.formTouched = true;
      this.selectedSocials.splice(value, 1);
    },
    addressHandler(val) {
      this.coords.set = false;
      this.formTouched = true;
      if (val.target.value.length > 2) {
        this.$services.AddressService.getSuggests(val.target.value)
          .then(({ data }) => {
            if (data.length) {
              this.addressHints = data;
            }
            return data;
          })
          .then((res) => {
            if (res.length) {
              this.showHints = true;
            }
          });
      } else {
        this.showHints = false;
      }
    },
    async validateAddress(address) {
      this.formTouched = true;
      this.address = address;
      this.showHints = false;
      if (address.length) {
        try {
          const standartized = await this.$services.AddressService.getFullAddress(this.address);
          if (!standartized.data.house) {
            throw new Error('Неполный адрес');
          }
          this.address = standartized.data.result;
          this.cladr = standartized.data.house_kladr_id;
          this.fias = standartized.data.house_fias_id;
          this.coords.lat = standartized.data.geo_lat;
          this.coords.long = standartized.data.geo_lon;
          this.coords.set = true;
          this.addressHints = [];
        } catch (err) {
          console.log(err);
          this.coords.set = false;
          this.$services.MessageService.error(
            'Ошибка при автоматическом заполнении адреса. Пожалуйста, введите полный адрес или поставьте точку на карте вручную'
          );
        }
      }
    },
    deleteCurator(id) {
      this.formTouched = true;
      this.curators.additional = this.curators.additional.filter(
        (user) => user !== id
      );
    },
    removeSelectedRegion(id) {
      this.formTouched = true;
      this.selectedRegions = this.selectedRegions.filter(
        (regionId) => regionId !== id
      );
    },
    removeSelectedPartnerAttributeId(id) {
      this.formTouched = true;
      this.selectedPartnerAttributeIds = this.selectedPartnerAttributeIds.filter((value) => value !== id);
    },
    clearSelectedRegions() {
      this.formTouched = true;
      this.selectedBusinessRegionIds = [];
    },
    async setCoords(coords) {
      this.cladr = '';
      this.fias = '';
      this.showHints = false;
      this.addressHints = [];
      this.formTouched = true;
      if (!this.isAuthUserAsAdmin && !this.isAuthUserRegional) {
        return;
      }
      this.coords.lat = coords[0];
      this.coords.long = coords[1];
      this.coords.set = true;
      this.$store.dispatch('map/setLoading', true);
      await window.ymaps
        .geocode([this.coords.lat, this.coords.long], { results: 1 })
        .then((result) => {
          const object = result.geoObjects.get(0);
          const props = object.properties.getAll();
          this.address = props.text;
          this.$services.AddressService.getSuggests(props.text)
            .then(({ data }) => {
              if (data.length) {
                this.addressHints = data;
              }
              return data;
            })
            .then((res) => {
              if (res.length) {
                this.showHints = true;
              }
            });
        });
      setTimeout(() => {
        this.$store.dispatch('map/setLoading', false);
      }, 1000);
    },
    async savePartner() {
      if (this.dataIsValid()) {
        const products = [];
        this.productGroups.forEach((group) => {
          const groupProductIds = group.products
            .filter((product) => product.selected)
            .map((product) => product.id);
          if (groupProductIds.length) {
            products.push(...groupProductIds);
          }
        });
        const partner = {
          name: this.partnerName,
          business_regions: this.selectedRegions,
          phone_1: this.phone_1 || '',
          phone_2: this.phone_2 || '',
          email_1: this.email_1 || '',
          email_2: this.email_2 || '',
          address: this.address || '',
          latitude: this.coords.lat,
          longitude: this.coords.long,
          web_site: this.website || '',
          type: this.partnerType || '',
          attributes_ids: this.selectedPartnerAttributeIds,
          is_sertified: this.hasCert,
          sertificate_links: [],
          certifications: this.updatedCertificates,
          certificateCA: this.certificateCA,
          inn: this.inn,
          description: this.description || '',
          curator: this.curators.main,
          additional_curators: this.curators.additional,
          cladr: this.cladr || '',
          fias: this.fias || '',
          is_show_on_map: this.mapVisible,
          products,
          social_networks: this.selectedSocials.map((social) => ({
            id: social.id,
            link: social.link
          }))
        };
        if (this.mode === 'create') {
          await this.createPartner(partner);
        } else if (this.mode === 'edit') {
          await this.updatePartner(partner);
        }
      } else {
        setTimeout(() => {
          this.$services.MessageService.warning(
            'Не заполнены обязательные поля'
          );
        }, 300);
      }
    },
    async createPartner(partner) {
      try {
        await this.$services.PartnerService.addPartner(partner);
        this.$services.MessageService.success('Партнер добавлен');
        setTimeout(() => {
          this.$router.push({ name: 'partners-list' });
        }, 2000);
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error('Ошибка при добавлении партнера');
      }
    },
    async updatePartner(partner) {
      try {
        await this.$services.PartnerService.updatePartner(
          partner,
          this.partnerId
        );
        this.$services.MessageService.success('Данные партнера изменены');
        setTimeout(() => {
          this.$router.push({
            name: 'partners-list',
            params: { other: this.$route.params.other }
          });
        }, 2000);
      } catch (err) {
        console.log(err);
        this.$services.MessageService.error('Ошибка при редактировании данных');
      }
    },
    dataIsValid() {
      if (!this.coords.set) {
        this.$services.MessageService.warning(
          'Необходимо указать местоположение партнера'
        );
        return false;
      }
      if (!this.email_1_valid || !this.email_2_valid) {
        return false;
      }
      if (
        !this.selectedRegions.length
        || !this.partnerName
        || (!this.phone_1 && !this.phone_2)
        || !this.curators.main
        || !this.partnerType
      ) {
        return false;
      }
      return true;
    },
    validateEmail(number) {
      this.formTouched = true;
      if (this[`email_${number}`] === '') {
        this[`email_${number}_valid`] = true;
        return;
      }
      this[`email_${number}_valid`] = ValidationHelper.emailIsValid(
        this[`email_${number}`]
      );
    },
    async deletePartner() {
      if (this.mode === 'create') {
        this.isSearch = false;
        this.closePartner();
      } else if (this.mode === 'edit') {
        try {
          await this.$services.PartnerService.deletePartner(this.partnerId);
          this.$services.MessageService.success('Данные успешно удалены');
          setTimeout(() => {
            this.isSearch = false;
            this.closePartner();
          }, 2000);
        } catch (err) {
          console.log(err);
          this.$services.MessageService.error('Ошибка при удалении данных');
        }
      }
    },
    closePartner() {
      this.$router.push({
        name: 'partners-list',
        params: { other: this.$route.params.other }
      });
    },
    parseDate(date) {
      if (date) {
        const dateObj = new Date(date);
        const addZero = (num) => (num > 9 ? num : `0${num}`);
        return `${addZero(dateObj.getDate())}.${addZero(
          dateObj.getMonth() + 1
        )}.${dateObj.getFullYear()}`;
      }
      return '';
    }
  },
  computed: {
    businessRegions() {
      return this.$store.getters['business_region/getRegions'];
    },
    selectedBusinessRegions() {
      const regionsMap = this.$store.getters['business_region/getRegionsMap'];
      return this.selectedRegions
        .map((id) => regionsMap.get(id))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    selectedPartnerAttributes() {
      return this.selectedPartnerAttributeIds
        .map((id) => this.attributes.find((item) => item.id === id))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    selectedAdditionalCurators() {
      return this.users
        .filter((user) => this.curators.additional.includes(user.id))
        .sort((a, b) => (a.full_name > b.full_name ? 1 : -1));
    },
    types() {
      return this.$store.getters['partner/getPartnerTypes'].filter((type) => this.selectedDirections.map((dir) => dir.id).includes(type.direction));
    },
    attributes() {
      return this.$store.getters['partner/getPartnerAttributes'].filter(
        (attr) => this.selectedDirections.map((dir) => dir.id).includes(attr.direction)
      );
    },
    socials() {
      return this.$store.getters['social/getSocials'];
    },
    selectedDirections() {
      const directions = this.$store.getters['directions/getDirections'];
      const selectedDirections = new Set(
        this.selectedBusinessRegions.map((region) => region.direction_id)
      );
      return directions.filter((direction) => selectedDirections.has(direction.id));
    },
    coordsToString() {
      return `${this.coords.lat || '0'}, ${this.coords.long || '0'}`;
    },
    markerType() {
      if (this.partnerType && this.selectedDirections.length === 1) {
        const partnerType = this.types.find(
          (type) => type.id === this.partnerType
        );
        return partnerType.image
          ? partnerType.image.thumbnail_link
          : partnerType.link;
      }
      return '/img/logo.svg';
    },
    /** Возможность взаимодействия с checkbox для отображения на карте */
    isDisabledMapVisible() {
      if (this.isAuthUserAsAdmin || this.isAuthUserRegional) {
        if (this.certificateCA['WS'] || this.certificateCA['BS']) {
          const certificateArray = Object.keys(this.certificateCA)
          .filter(key => this.certificateCA[key].certificates !== undefined && this.certificateCA[key].certificates.length > 0)
          .map(key => ({
            key,
            certificates: this.certificateCA[key].certificates
          }));

          if (!certificateArray.map((item) => item.certificates[item.certificates.length - 1]?.valid) && this.isAuthUserRegional) {
            return true;
          }
          return false;
        }
        return false;
      }

      return true;
    },
    isAuthUserAsAdmin() {
      const adminRoleId = this.$store.getters['user/getAdminRoleId'];
      const authUser = this.$store.getters['auth/getAuthUser'];
      return authUser.role_id === adminRoleId;
    },
    isAuthUserRegional() {
      const regionalRoleId = this.$store.getters['user/getRegionalRoleId'];
      const authUser = this.$store.getters['auth/getAuthUser'];
      return authUser.role_id === regionalRoleId;
    },
    isValidCertificate() {
      return false;
    }
  }
};
</script>

<style scoped>
.user-page-wrapper {
  padding-bottom: 100px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.user-create {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}

.title {
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
}

.btn-wrapper {
  display: flex;
  padding: 15px 7.5px;
  justify-content: space-between;
  align-items: center;
  background: #e5e5e5;
  border: 1px solid #bfbfbf;
  border-top: 0;
}

.btn-top {
  margin: 0 7.5px;
}

.create {
  border: 1px solid #bfbfbf;
}

.form {
  padding: 20px 25px 80px 25px;
  background: #f2f2f2;
  width: 100%;
}

.tabs {
  display: flex;
}

.tab {
  font-size: 18px;
  line-height: 24px;
  padding: 13px 24px;
  cursor: pointer;
}

.tab.active {
  background: #000000;
  color: #ffffff;
}

.tab-content {
  display: flex;
}

.content-item {
  width: 50%;
  max-width: 733px;
  margin-right: 50px;
}

.form-item--parameters {
  width: 356px;
}

.text {
  font-size: 16px;
  line-height: 18px;
  color: #4e4e4e;
}

.text-small {
  font-size: 14px;
  line-height: 17px;
  color: #4e4e4e;
}

.form-item {
  position: relative;
  margin-bottom: 20px;
}

.form-input {
  display: block;
  width: 100%;
  min-height: 32px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  margin-bottom: 5px;
  padding: 0 10px;
}

.form-input:read-only {
  background-color: #e5e5e5;
  cursor: default;
}

.form-textarea {
  height: 210px;
  overflow-y: auto;
  padding: 10px;
  outline: none;
  border-radius: 0;
}

.form-input.error,
.form-select.error {
  border: 1px solid #dc143c;
}

.message-error {
  color: #dc143c;
  font-size: 14px;
  line-height: 17px;
}

.form-label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.form-item-wrapper {
  display: flex;
  justify-content: space-between;
}

.form-item-wrapper .form-item {
  margin: 0 15px 20px 0;
  width: 50%;
}

.form-item-wrapper .form-item:nth-child(2n) {
  margin-right: 0;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.form-select__checkbox {
  position: relative;
  padding-left: 39px;
}

.form-select__checkbox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  width: 19px;
  height: 19px;
  border: 1px solid #000;
  background-color: #fff;
}

.form-select__checkbox::after {
  content: url("../../assets/images/svg/checked.svg");
  position: absolute;
  top: -7px;
  left: 13px;
  width: 14px;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #000;
  font-weight: 400;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  display: none;
}

.el-select-dropdown__item.selected .form-select__checkbox::before {
  background-color: #000;
}

.form-radio-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.form-label-check,
.form-label-select,
.form-label-radio {
  position: relative;
  display: inline-block;
  padding-left: 29px;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
}

.form-label-radio:not(:last-child) {
  margin-right: 20px;
}

.form-label-check::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  background: #fff;
  border: 1px solid #4e4e4e;
}

.form-label-check::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: url("../../assets/images/svg/checked-black.svg") no-repeat;
  opacity: 0;
  z-index: 2;
}

.form-label-check_black::after {
  background: url("../../assets/images/svg/checked.svg") no-repeat;
}

.form-input-check,
.form-input-radio {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.form-input-check[type="checkbox"]:checked + .form-label-check_black::before {
  background-color: #000;
}

.form-input-check[type="checkbox"]:checked + .form-label-check::after {
  opacity: 1;
}

.form-label-radio::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid #000;
  background-color: #fff;
}

.form-input-radio:checked + .form-label-radio::before {
  background-color: #000;
}

.text-small {
  font-size: 16px;
  line-height: 18px;
  color: #4e4e4e;
}

.facility-list-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 30px;
}

.facility {
  display: flex;
  justify-content: space-between;
}

.facility-item {
  width: 48%;
}

.form-select {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid #000000;
  margin-bottom: 5px;
}

.el-select .el-input__suffix {
  background: #000000;
}

.el-select .el-input .el-select__caret {
  color: #ffffff;
  font-size: 20px;
}

.el-select .el-select__caret {
  color: #ffffff;
  font-size: 20px;
}

.el-select .el-icon-arrow-up:before {
  color: #ffffff;
  font-size: 20px;
}

.el-select .el-input__prefix,
.el-input__suffix {
  color: #ffffff;
  right: 0;
  width: 40px;
  height: 40px;
}

.el-select .el-input__suffix {
  color: #ffffff;
  right: 0;
  width: 40px;
  height: 40px;
}

.el-select .el-input__inner {
  padding-left: 10px;
  padding-right: 0;
  border-radius: 0;
  font-size: 18px;
  color: #000000;
  line-height: 24px;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #000000;
}

.el-select .el-input__inner:focus {
  border-color: #000000;
}

.el-select-dropdown__list {
  padding: 0;
}

.el-select-dropdown__item {
  padding: 10px;
  height: auto;
  color: #000000;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #58bcaf;
  color: #ffffff;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.select-checkbox {
  position: relative;
  padding-left: 49px;
}

.el-select-dropdown.is-multiple
  .el-select-dropdown__item.select-checkbox::after {
  content: "";
  position: absolute;
  top: 17px;
  left: 14px;
  width: 12px;
  height: 12px;
  background: url("../../assets/images/svg/checked.svg") no-repeat;
  opacity: 0;
  z-index: 2;
}

.el-select-dropdown.is-multiple
  .el-select-dropdown__item.select-checkbox.selected::after {
  opacity: 1;
}

.users-table__icon {
  width: 9px;
  height: 14px;
  margin-left: 5px;
}

.region-title {
  display: flex;
  align-items: baseline;
}

.region-list {
  border-top: 2px solid #000000;
}

.region-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 10px;
  font-size: 18px;
  line-height: 24px;
  transition: all 0.3s ease-in-out;
  background: #ffffff;
}

.region-list__item:hover {
  background: #e5e5e5;
}

.region-list__close-icon {
  width: 14px;
  height: 14px;
}

.region-list__icon {
  width: 14px;
  height: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.socials-list {
  padding: 15px;
  background: #ffffff;
  text-align: center;
  margin-bottom: 15px;

  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #4e4e4e;
}

.socials-table {
  margin-bottom: 15px;
  width: 100%;
}

.socials-table th,
td {
  text-align: left;
  padding: 15px;
}

.socials-table tr.socials-table__head {
  background: transparent;
}

.socials-table tr {
  background: #ffffff;
}

.socials-table tr:hover,
.socials-table tr:nth-child(even) {
  background: #e5e5e5;
}

.socials-table tr.socials-table__head:hover {
  background: transparent;
}

.socials-table th {
  border-bottom: 1px solid #000000;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.socials-table td {
  font-size: 18px;
  line-height: 24px;
}

.socials-table td img {
  max-height: 24px;
  max-width: 24px;
  cursor: pointer;
}

.download {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.form-hints {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  padding: 5px 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.form-hints li {
  width: 100%;
  padding: 5px;
  cursor: pointer;
}

.form-hints li:hover {
  background-color: #f2f2f2;
}

.form-hints li:not(:last-child) {
  margin-bottom: 5px;
}
</style>
